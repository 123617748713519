@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');


/* COLORS */

$gray-100: hsl(0, 0%, 22%);
$gray-200: hsl(0, 0%, 42%);
$gray-300: hsl(0, 0%, 62%);
$gray-400: hsl(0, 0%, 82%);
$gray-500: hsl(0, 0%, 92%);
$gray-600: hsl(0, 0%, 96%);

$blue-gray-100: hsl(204, 14%, 35%);
$blue-gray-200: hsl(204, 14%, 40%);
$blue-gray-300: hsl(204, 14%, 50%);
$blue-gray-400: hsl(204, 14%, 60%);
$blue-gray-500: hsl(204, 14%, 70%);
$blue-gray-600: hsl(204, 14%, 80%);
$blue-gray-700: hsl(204, 14%, 90%);
$blue-gray-800: hsl(204, 14%, 95%);
$blue-gray-900: hsl(204, 14%, 97%);

$acc_red: #c72727;
$acc_dark_red: #8d0f0f;
$acc_gray: #eaeaea;

.color-blue-gray-500 {
    color: $blue-gray-500;
}

.blue-gray-gradient {
    background: rgb(139,156,167);
    background: linear-gradient(138deg, rgba(139,156,167,1) 0%, rgba(110,131,145,1) 35%, rgba(88,105,116,1) 100%);
}

/* GENERAL */

html { scroll-behavior: smooth; }
body { 
    font-family: 'Inter', 'MV Faseyha', sans-serif; 
    color: $blue-gray-100;
    overflow-x: hidden;
    background-color: #ECEFF4;
}

a { 
    text-decoration: none;
    color: $blue-gray-200;

    &:hover,
    &.show { 
        color: $blue-gray-300; 
    }
}

main, header {
    .container-white-bg {
        background-color: white;
        // border-left: 1px solid $blue-gray-700;
        // border-right: 1px solid $blue-gray-700;
    }
}

.font-switcher {
    font-size: 1.1rem;
}

/* Nav Tabs */

.nav-tabs .nav-link {
    color: $blue-gray-100;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: $blue-gray-100;
    border-color: #dee2e6 #dee2e6 #fff;
}

/* CLASSES */

.form {
    fieldset {
        background-color: $blue-gray-900;
        padding: 1rem;
        margin-bottom: 0.875em;
    }

    legend {
        margin-bottom: 1em;
    }

    .accused-person, .witness, .other_participants_info {
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid $blue-gray-600;
    }
}

.services-subheader {
    max-width: 600px;
    text-align: center
}

.social-icon {
    &.facebook { color: #3b5998; }
    &.twitter { color: #00acee; }
    &.youtube{ color: #c4302b; }
    &.instagram { color: #000; }
}

.share-icons {
    svg {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: transform .2s;
        &:hover { transform: scale(1.1); }
    }
}

.logo {
    img { width: 125px; }

    &__name { 
        
        h2 {
            font-size: 0.85rem;
            font-weight: bold;
            margin-bottom: 0;
        }

        p {
            font-size: 0.750rem;
            color: #666;
            margin-bottom: 0;
        }
    }

    a { color: $blue-gray-100; }

    &--hori {
        display: flex; 
        align-items: center;
        flex-direction: row;
        img { width: 90px; }
    }

    &--vert {
        img { margin-bottom: 1em; }
    }

}


.no-dv {
    direction: ltr !important;
}

/* COMPONENTS */

// Buttons
.btn-success, .btn-primary {
    font-size: 1.1rem;
    color: #fff;
    background-color: $blue-gray-200;
    border-color: $blue-gray-200;
    &:focus {
        background-color: $blue-gray-300;
        border-color: $blue-gray-300;
    }
    &:active,
    &:hover {
        color: $blue-gray-200;
        background-color: #fff;
        border-color: $blue-gray-200;
    }
}

.btn-outline-success, .btn-outline-primary {
    font-size: 1.1rem;
    color: $blue-gray-200;
    border-color: $blue-gray-200;
    &:active,
    &:hover {
        color: #fff;
        background-color: $blue-gray-200;
        border-color: $blue-gray-200;
    }
}

.btn-default {
    color: #fff;
    background-color: $blue-gray-200;
    border-color: $blue-gray-200;

    &:active,
    &:hover {
        color: $blue-gray-200;
        background-color: #fff;
        border-color: $blue-gray-200;
    }

}
.btn-outline-default {
    color: $blue-gray-200;
    border-color: $blue-gray-200;

    &:active,
    &:hover {
        color: #fff;
        background-color: $blue-gray-200;
        border-color: $blue-gray-200;
    }
}

.btn-white {
    color: $blue-gray-200 !important;
    background-color: #fff;
    border-color: #fff;

    &:active,
    &:hover {
        color: $blue-gray-200 !important;
        background-color: rgb(241, 241, 241);
        border-color: rgb(241, 241, 241);
    }

}

.btn-success.disabled, .btn-success:disabled {
    color: #525252;
    background-color: #adadad;
    border-color: #adadad;
}

// Committee

.committee-list {
    .committee:last-of-type {
        border-bottom: none;
    }
}

.committee {
    margin-bottom: 2rem;
    padding: 1rem;
    border-bottom: 1px solid $blue-gray-600;

    &__title {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    &__member {
        display: inline-block;
        text-align: center;
        margin: 1rem;
        p { margin-bottom: 0; }
        img {
            width: 100px;
            border-radius: 50px;
            height: 100px;
            margin-bottom: 0.5rem;
            object-fit: cover;
        }
    }
}

// Post
.post {
    border-radius: 0;

    a { 
        color: $blue-gray-200; 
        &:hover{
            color: $blue-gray-300; 
        }
    }

    h3 {
        font-size: 0.875rem;
        font-weight: bold; 
        line-height: 1.4;
    }

    &__image {
        width: 100%;
        margin-bottom: 1em;
    }

    .card-img { border-radius: 0; }
    .card-body { padding: 0.75rem; }

    &__date {
        font-size: 0.875em;
        color: $blue-gray-500;
    }
}

.post--horizontal {
    display: flex;
    // border-bottom: 1px solid #f0f3f4;
    
    .post__image {
        height: 125px;
        width: 175px;
        object-fit: cover;
        overflow: hidden;
        border: 1px solid #D8DEE9;

        &--small {
            height: 100px;
            width: 125px;
        }
    }

    .post__details {
        align-items: center;
        padding: 0em 1.2em;
        width: 80%;

        h5 { 
            font-weight: bold;
            font-size: 1.3rem; 
        }

        p { font-size: 0.9rem; }
    }
}

.post--vertical {
    .post__image {
        width: 100%;
        height: 200px;
        object-fit:cover;
    }
}

.post--featured {

    a {
        color: $blue-gray-200; 
    }

    h3 { 
        font-size: 1.5rem;
        margin-bottom: 0.875rem;
    }

    img {
        max-height: 460px;
        object-fit: cover;
    }

    margin-bottom: 1em;

    &.post--horizontal {

        .post__image { 
            object-fit: cover; 
            margin: 0;
            width: 60%;
            height: auto;
        }

        .post__details {
            width: 40%;
            align-items: center;
            // background-color: $gray-500;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 2em;


            h5 { 
                font-weight: bold;
                font-size: 1.5rem; 
            }

            p { font-size: 0.9rem; }
        }
    }

}



// Home Page Announcement
.home-announcements {
    h4 {
        color: $blue-gray-200;
    }
    .announcement {
        border-bottom: 1px dashed #e5e9f0;
        padding: 0.5rem 0 0.5rem 0.5rem;
        display: flex;

        &__title {
            font-weight: normal;
            // word-break: break-all;
            margin-bottom: 0.3rem;
            color: $blue-gray-300; 
            &:hover{
                color: $blue-gray-100; 
            }

        }
    
        &__date {
            font-size: 0.875rem;
            color: $blue-gray-400;
            margin-bottom: 0;
            padding: 0.3rem 0.8rem 0.3rem 0.8rem;
        }
    }
}


// Announcements & downloads
.announcement-list {
    ul {
        list-style: none;

        .list-group-item {
            border-bottom: 1px solid $blue-gray-800;
        }
    }
    .item-type {
        color: $blue-gray-300;
    }
    .item-title {
        color: $blue-gray-100;
        display: block;
        margin-bottom: 5px;
    }
    .item-time {
        color: $blue-gray-400;
        i {
            color: $blue-gray-500; 
            font-size: 0.8rem;
        }
    }
}

// Flipbook

#flipbookContainer { 
    border: 1px solid $blue-gray-700;
    background-color: $blue-gray-900;
}

// Block footer
/* LAYOUT SECTIONS */

#search-container { padding: 1em; }


#page-footer {
    background-color: #434C5E;
    // border-top: 1px solid $blue-gray-800;
    color: $blue-gray-800;
    padding: 1rem 0;
    margin-top: 2.5rem;
    font-size: 0.9rem;

    .bottom { padding-top: 1rem; }
    .top { border-bottom: 1px solid $blue-gray-100 }
    .social-icon { 
        color: #fff;
        margin: 0 0.25rem 0 0.25rem; 
        font-size: 1.5rem;
    }

    h5 {
        margin-bottom: 0.75em;
        color: $blue-gray-800;
        font-size: 1rem;
    }

    a {
        color: $blue-gray-800;

        &:hover {
            color: $blue-gray-700;
        }
    }

    address {
        p {
            font-size: 1rem;
            padding-bottom: 0px;
            color: $blue-gray-800;
            margin-bottom: 0.05em;
        }
    }
}

#page {
    margin-bottom: 0px !important;

    .page__title {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }

    .page__section {
        margin-bottom: 2rem;

        &:only-of-type,
        &:last-of-type
        {
            border-bottom: none;
        }

        .section__title {
            margin-bottom: 0.875rem;
            border-bottom: 1px solid #E5E9F0;

            &__text {
                font-weight: bold;
                font-size: 1.3rem;
            }
        }

        .section__footer {
            text-align: right;
            padding: 0.2rem 0.5rem;
            font-size: 0.875rem;
            margin-top: 0.8rem;
        }

        &--top-spaced {
            margin-top: 8em;
        }

        &--spaced {
            margin: 3em 0;
        }

        &--padded {
            margin-top: -7rem;
            padding-top: 6rem;
        }
        
        &--top-bordered {
            border-top: 1px solid $gray-500;
            padding-top: 1em;
        }
    }


    .page__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        align-items: center;
        margin-bottom: 1rem;
        padding: 1rem 0;
        z-index: 1;

        &--sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
        }
    }


    .page__navigation {
        
        .filter {
            &__label {
                // color: #fff;
                // background-color: #434C5E;
                font-size: 1.2rem;
                font-weight: normal;
                padding: 4px;
            }
            .dropdown-toggle {
                color: $blue-gray-200
            }
        }

        .dropdown-item {
            font-family: MV Waheed;
            font-weight: normal;
            color: $blue-gray-200;
        }

        .nav-item {
            font-size: 0.875rem;
        }

        .nav-link {
            &.active {
                font-weight: bold;
            }
        }
    }

    .page__content {
        position: relative;
        // text-align: justify;
    }
}

.banner-row {
    background-color: #da4958;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23FFFFFF' stroke-width='10' stroke-opacity='0.12'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 200;

    .banner {
        display: flex;
        color: #fff;
        padding: 2em;
    
        h5 {
            font-size: 3rem;
            line-height: 1;
        }
    
        h6 {
            font-size: 2rem;
            line-height: 1;
        }
        .accordion-item {
            background-color: transparent;
            border: none;
            .accordion-body {
                background-color: #9e1422cc;
                color: #fff;
                border: 1px solid #a71a28;

                a {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        color: #fff;
                    }
                }
            }
            .accordion-button {
                background-color: transparent;
                color: #fff;
                font-size: 1.2rem; 
                &:focus {
                    border-color: none;
                    box-shadow: none;
                }
                &:not(.collapsed) {
                    color: white;
                    background-color: #a52431cc;
                    box-shadow: none;
                }
            }
        }
    }
}

#top-bar {
    padding: 0.5em 0;
    font-size: 1rem;
    
    .search {
        margin: 0 1em;
        color: $gray-100;
    }
}

#top-nav {
    .container { 
        border-bottom: 1px solid #E5E9F0;
        border-top: 1px solid #E5E9F0;
    } 
    .nav-link {
        font-weight: 600;
        display: flex;
        align-items: center;

        &:hover,
        &.show { 
            color: $blue-gray-300; 
        }

        .toggler-icon {
            color: $blue-gray-500;
            font-size: 0.5em;
            margin-left: 0.3em;
            stroke-width: 1px;
            -webkit-text-stroke-width: 1px;

        }

        color: $blue-gray-100;
    }

    .navbar-toggler:focus { box-shadow: none; }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }


    .navbar-brand { color: $blue-gray-100 }

    .dropdown-menu { 
        font-size: 0.875em; 
        border: 1px solid #E5E9F0;
    }

    .dropdown-item { color: $blue-gray-200; }

    .dropdown-item.active,
    .dropdown-item:active { color: #fff; }

    #navbar-content { z-index: 2000; }
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: $blue-gray-300;
}

/* HOME PAGE */

/* Action buttons */
.action-row {
    background-color: #434C5E;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23ECEFF4' stroke-width='10' stroke-opacity='0.08'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.actions__button {
    a {
        padding: 1.25em;
        background: #fff;
        position: relative;
        border: 0px solid #efefef;
        color: $gray-100;
        display: block;

        -webkit-transition: .5s all ease;
        -o-transition: .5s all ease;
        transition: .5s all ease;

        box-shadow: 0px 4px 4px -3px rgba(0,0,0,0.1);
        -webkit-box-shadow: 0px 4px 4px -3px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 4px 4px -3px rgba(0,0,0,0.1);

        &:hover {
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
        }
    }

    h3 {
        font-size: 1rem;
        font-weight: 400;
    }

    .icon { 
        font-size: 3rem;
        margin-top: 5px;
    }

    .report:hover {
        background-color: #dd0d0d;
        color: #fff !important;
    }

    .check-status:hover {
        background-color: #e0ae16;
        color: #fff !important;
    }

    .record-clearance:hover {
        background-color: #198754;
        color: #fff !important;
    }

    .rti-request:hover {
        background-color: #00b4d8;
        color: #fff !important;
    }
}

/* Image slider */
.slider {
    position: relative; 
    display: flex;

    .slider__image { 
        width: 100%;
        border-radius: 0rem;
        overflow: hidden;
        // max-height: 450px;
        border: 1px solid #ECEFF4;
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .slider__text {
        width: 30%;
        color: $blue-gray-200;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2em;


        h5 { 
            font-weight: bold;
            font-size: 2rem; 
        }

        p { font-size: 1.3rem; }
    }

    .slider__controls {
        font-size: 3em;
        direction: ltr !important
    }
}

/* CONTACT PAGE */

.contact-group {
    h3 {
        margin-bottom: 1em;
        font-size: 1.5em;
    }

    h4 {
        font-size: 1.025em;
        margin-top: 0.5em;
        margin-bottom: 1em;
    }

    ul { margin-bottom: 2em; }
    i { color: $blue-gray-600; }
}

.location-map {
    background-color: $gray-400;
    height: 100%;
    margin-bottom: 2em;
}

.contact, .contact-group {
    padding: 0.5em;
    li {
        font-family: 'Inter', 'MV Faseyha', sans-serif !important; 
        font-size: 1rem !important;
    }
}

.publication {
    a { color: $blue-gray-100; }

    &__cover {
        height: 250px;
        object-fit: cover;
        margin-bottom: 0.5rem;
        border: 1px solid #d7dce6;
        box-shadow: 1px 2px 5px #c1c8d4;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;

        &:hover {
            -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
        }
    }

    &__details { padding: 0rem; }

    &__title {
        font-size: 1.2rem;
        font-weight: normal;
        text-align: center;
        margin-bottom: 0.5rem;
        width: 150px;
        margin-right: auto;
        margin-left: auto;
    }

    &__date {
    }
}

.publication__research {
    background-color: red;
}

.document-item {
    border: 1px solid black;
    height: 5em;
}

.person {
    display: flex;
    margin: 1rem;
    justify-content: center;
    align-items: center; 

    &__imagecontainer {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $blue-gray-700;
    }
    &__photo {
        object-fit: cover;
        object-position: 50% 0%;
        width: 100%;
        height: 100%;
    }

    &__info {
        h4 {
            margin: 0;
            font-weight: bold;
        }
        p {
            color: $blue-gray-300
        }
    }

    &__title {
        font-size: 0.875rem;
        font-weight: bold;
    }

    &--no-profile {
        .person__photo {
            width: 100%;
            height: 100%;
        }
        .person__info {
            width: 40%;
        }
    }
}

/* OVERRIDES */ 

.btn:focus { box-shadow: none; }

.badge, .btn, .dropdown-menu, .card-newsitem, .list-group, .nav-pills .nav-link, .card {
    border-radius: 0px !important;
}

// Allerts

.alert-warning {
    color: #664d03;
    background-color: #fff3cd78;
    border-color: #ffecb5;
}

// Sweat alert
.swal2 {
    &-container { z-index: 3000 !important; }
    &-popup { border-radius: 0px !important; }
    &-styled.swal2-confirm {
        border-radius: 0px !important;
        background-color: $blue-gray-200 !important;
    }
    &-styled.swal2-confirm:focus {
        box-shadow: none !important;
    }
}
// Forms
.form-control {
    border: 1px solid $blue-gray-600;
    border-radius: 0px;
}

.form-control:focus {
    color: $blue-gray-100;
    background-color: #fff;
    border-color: $blue-gray-200;
    outline: 0;
    box-shadow: none;
}

// Multistep Form

#form-container {
    background-repeat: repeat;
    position: relative;
    padding: 0px;
    // min-height: 630px;
    border: 1px solid $blue-gray-700;
    background-color: $blue-gray-900;
}

#steps-container { margin: auto; }

.step { display: none; }
 
.step h4 {
    margin: 0 0 26px 0;
    padding: 0;
    position: relative;
    font-weight: 500;
    font-size: 23px;
    font-size: 1.4375rem;
    line-height: 1.6;
}
 
.progress { border-radius: 0px !important; }

.bg-progress { background-color: $blue-gray-200; }
 
// Paginator
.pagination {
    margin-top: 40px;
    font-family: 'Inter', 'MV Faseyha', sans-serif; 
}
.page-link {
    color: $blue-gray-500;
    border: 0px;
}

.page-item.active {
    .page-link {
        z-index: 3;
        color: $blue-gray-100;
        background-color: #ffffff;
        border-color: $blue-gray-600;
    }
}

.page-link {
    &:hover {
        z-index: 2;
        color: $blue-gray-100;
        background-color: $blue-gray-600;
        border-color: $blue-gray-600;
    }
    &:focus {
        box-shadow: none;
    }
}

.list-group-item {
    border: 1px solid #D8DEE9;
    color: $blue-gray-200;
}

.text-muted { color: $blue-gray-400 !important; }

// Sidebar
.sidebar{ 
    font-size: 1rem;
    border: none;
    border: 1px solid $blue-gray-800;
    
    ul { list-style: none; }

    .list-group-item {
        background-color: inherit;
        border-bottom-style: dashed;
    }
}


ol {
    list-style: none;
    counter-reset: list-counter;
    li {
        counter-increment: list-counter;
        &::before {
            content: counter(list-counter) ". ";
            color: $blue-gray-400;
        }
    }
}

// PDF List
.pdf-list {
    ul {
        list-style: none;

        .list-group-item {
            border-bottom: 1px solid #E5E9F0;
        }
        
        li::before {
            content: "\f63e";
            font-family: bootstrap-icons !important;
            padding: 0.6rem;
            color: $acc_red;
        }
    }
}

// Accordion
.accordion-item {

    border-bottom: 1px solid $blue-gray-700;

    .accordion-button {
        color: $blue-gray-200;
        font-size: 1.2rem;
        &:focus {
            border-color: none;
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: white;
            background-color: $blue-gray-200;
            box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
            
            &::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                transform: rotate(-180deg);
            }
        }
    }
    .accordion-body { 
        background-color: $blue-gray-900;
        border: 1px solid $blue-gray-700;
    }
}

// Table
table {
    color: $blue-gray-100 !important;
}

/* Affiliates */
.affiliates {
    display: flex;
    justify-content: center;

    .affiliate {
        max-width: 50px;

        img {
            width: 100%;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            -webkit-transition: .5s all ease;
            -o-transition: .5s all ease;
            transition: .5s all ease;
            opacity: 0.5;
            border-radius: 50%;
        }

        a:hover {
            img {
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
              opacity: 1;
            }
        }
    }
}

.min-vh-75 {
    min-height: 75vh!important;
}

/* Media Queries */

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .page__header {
        flex-direction: column;
    }
    .actions__button {
        height: 155px;
        margin-bottom: 16px;
        a {
            height: 100%;
            padding: 0.6em;
        }
        .icon { font-size: 2em; }
        h3 { 
            font-size: 0.9rem;
            width: 80px;
            margin-right: auto;
            margin-left: auto;
         }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

    .navbar {
        padding-top: 0px;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.7rem;
        padding-left: .7rem;
    }

    .navbar-brand {
        margin-right: 0;
    }

    .logo {
        img { width: 80px; }
        // &__name { display: none; }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .slider {
        flex-direction: column;
        .slider__image {
            width: 100%;
        }
        .slider__text {
            width: 100%;
            padding: 2em;
        }
    }
    .page__content {
        padding-left: 5px;
        padding-right: 5px;
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    
    #top-nav {
        .nav-link {
            border-bottom: 1px solid $blue-gray-700;
        }

        .dropdown-menu {
            border: none;
        }
    }
    .offcanvas-start {
        padding-top: 12px;
        width: 350px;

        .navbar-nav {
            width: 100%;

            .dropdown-menu {
                font-size: 1rem !important;
                padding-top: 0px;
                padding-bottom: 0px;
            }
            
            .nav-link {
                padding-right: 20px !important;
                padding-left: 20px !important;
            }
            .dropdown-item {
                padding-right: 40px !important;
                padding-left: 40px !important;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
    
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}

@media all and (min-width: 1199.98px) {
	.navbar {
        .dropdown-menu-end { 
            right:0; 
            left: auto;
        }
        .nav-item .dropdown-menu {  
            display:block; 
            opacity: 0;  
            visibility: hidden;
            transition:.3s;
            margin-top:0;
        }
        .nav-item:hover .nav-link {
            color: #fff;
        }
        .dropdown-menu.fade-down {
            top:80%; transform: rotateX(-75deg);
            transform-origin: 0% 0%;
        }
        .dropdown-menu.fade-up {
            top:180%;
        }
        .nav-item:hover .dropdown-menu {
            transition: 0.5s;
            opacity:1;
            visibility:visible;
            top:100%;
            transform: rotateX(0deg);
        }
    }
}

@media all and (min-width: 991.98px) {
    .page__content {
        padding-left: 30px;
        padding-right: 30px;

        .main-content {
            padding-left: 30px;
        }
    }
}

@media all and (min-width: 767.98px) {
    .container_padded {
        padding-left: 3rem!important;
        padding-right: 3rem!important;
    }
}